import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { container, fade, loader } from './Loader.module.scss';

const LoaderContainer = styled.aside`
  position: ${({ fullpage }) => (fullpage ? 'fixed' : 'absolute')};
`;

const Loader = ({ show, fullPage }) => {
  const [fadeIn, setFadeIn] = useState(show);
  const [visible, setVisible] = useState(show);
  const [fadeTimeout, setFadeTimeout] = useState(null);

  const showLoader = () => {
    clearTimeout(fadeTimeout);
    setVisible(true);
    setFadeIn(true);
  };

  const hideLoader = () => {
    setFadeIn(false);

    setFadeTimeout(
      setTimeout(() => {
        setVisible(false);
      }, 500),
    );
  };

  useEffect(() => (show ? showLoader() : hideLoader()), [show]);

  return (
    visible && (
      <LoaderContainer
        fullpage={fullPage}
        className={classNames(container, {
          [fade]: fadeIn
        })}
      >
        <div className={loader}>
          <div />
          <div />
        </div>
      </LoaderContainer>
    )
  );
};

Loader.propTypes = {
  fullPage: PropTypes.bool,
  show: PropTypes.bool,
};

Loader.defaultProps = {
  fullPage: false,
  show: false,
};

export default Loader;
