export const ERROR_TYPE = {
  REQUIRED_FIELD:          'This field is required.',
  INVALID_CREDIT_CARD:     'Enter valid card number',
  INVALID_CREDIT_CVV:      'Enter valid cvv number',
  INVALID_EMAIL:           'Invalid email field.',
  INVALID_REG_EXP:         'Invalid regular expression.',
  INVALID_PHONE:           'Invalid phone number.',
  INVALID_CURRENCY:        'Invalid currency value.',
  INVALID_DATE:            'Invalid date.',
  INVALID_EXPIRATION_DATE: 'Invalid expiration date.',
  INVALID_ZIP_CODE:        'Invalid zip code.',
  INVALID_MAX_LENGTH:      'Value is too long.',
  INVALID_ALCOHOL_AGE:     'You must be at least 21 years old to place an order with alcohol.',
  OUT_OF_STOCK:            'OUT_OF_STOCK',
};
