import React from 'react';
import { noop } from 'lodash';

export default React.createContext({
  unregisterControl: noop,
  registerControl: noop,
  forceValidate: noop,
  getAllErrors: noop,
  showErrors: noop,
  getErrors: noop,
  setErrors: noop,
  onErrors: noop,
  submit: noop,

  getAllShowErrorsStatus: noop,
  getShowErrorsStatus: noop,

  isValid: false,
  isSubmitting: false,
  hideInputErrors: false,
  isSubmitAvailable: false,
});
