export const getIsAmericanExpress = (cardNumber) => {
  return cardNumber !== null
    ? (cardNumber.toString().substring(0, 2) === '34' || cardNumber.toString().substring(0, 2) === '37')
    : false;
};

export const CardType = {
  default: 'default',
  americanExpress: 'americanExpress',
};

export const getCardType = (cardNumber) => {
  if(getIsAmericanExpress(cardNumber)){
    return CardType.americanExpress;
  }

  return CardType.default;
};
