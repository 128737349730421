import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import TextMask from 'react-text-mask';

import TextInput from './TextInput';

const MaskedInput = ({
  maskPlaceholder,
  placeholder,
  inputProps,
  inputMask,
  onChange,
  value,
  ...restProps
}) => (
  <TextMask
    {...restProps}
    mask={inputMask}
    placeholder={placeholder}
    placeholderChar={maskPlaceholder}
    render={(ref, props) =>
      <TextInput
        ref={ref}
        value={value}
        {...props}
        {...inputProps}
        unpackEvent={false}
        onChange={e => props.onChange(e) || onChange(e.target.value, [], e)}
      />
    }
  />
);

export const MaskType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.bool,

  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  ),

  PropTypes.shape({
    pipe: PropTypes.func,
    mask: PropTypes.func.isRequired,
  }),
]);

MaskedInput.propTypes = {
  value: PropTypes.string,
  maskPlaceholder: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  inputMask: MaskType,
};

MaskedInput.defaultProps = {
  maskPlaceholder: ' ',
  placeholder: ' ',

  inputProps: null,
  inputMask: false,
  onChange: noop,
};

export default MaskedInput;

export const maskNumbers = numbers =>
  new Array(numbers).fill(/\d/);
