import { isEmpty, isString } from 'lodash';
import { getNow, parseDate } from './DateTime';

import { ERROR_TYPE } from './data/Constants';
import {
  EMAIL,
  getCardNumberRegexpByType,
  getCvvCodeRegex,
  US_CURRENCY,
  US_PHONE_DIGITS,
  US_PHONE_REGEXP,
  ZIP_CODE,
} from './RegExp';

export const ValidatorName = {
  REQUIRED        : 'required',
  EMAIL           : 'email',
  REG_EXP         : 'regexp',
  PHONE           : 'phone',
  CARD            : 'card',
  CVV             : 'cvv',
  CURRENCY        : 'currency',
  DATE            : 'date',
  EXPIRATION_DATE : 'expDate',
  MAX_DATE        : 'maxDate',
  ZIP_CODE        : 'zipCode',
  MAX_LENGTH      : 'maxLength',
};

export default new Map([
  [ValidatorName.REQUIRED, {
    errorMessage: ({ message = ERROR_TYPE.REQUIRED_FIELD }) => message,
    isValid: value => !isEmpty(value),
  }],

  [ValidatorName.CARD, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_CREDIT_CARD }) => message,
    isValid: (cardNumber, params) => {
      const { cardType } = params;

      return getCardNumberRegexpByType(cardType).test(cardNumber);
    },
  }],

  [ValidatorName.CVV, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_CREDIT_CVV }) => message,

    isValid: (value, param) => {
      const { currentCardType } = param;

      return getCvvCodeRegex(currentCardType).test(value);
    },
  }],

  [ValidatorName.EMAIL, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_EMAIL }) => message,
    isValid: mail => EMAIL.test(mail),
  }],

  [ValidatorName.REG_EXP, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_REG_EXP }) => message,
    isValid: (value, exp) => exp.test(value),
  }],

  [ValidatorName.PHONE, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_PHONE }) => message,

    isValid: phone => isString(phone) && !!(
      phone.match(US_PHONE_REGEXP) || phone.match(US_PHONE_DIGITS)
    ),
  }],

  [ValidatorName.CURRENCY, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_CURRENCY }) => message,
    isValid: value => isEmpty(value) || US_CURRENCY.test(value),
  }],

  [ValidatorName.DATE, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_DATE }) => message,
    isValid: (value, format) => parseDate(value, format).isValid(),
  }],

  [ValidatorName.EXPIRATION_DATE, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_EXPIRATION_DATE }) => message,

    isValid: (value, format) => {
      if (!value) {
        return false;
      }
      const date = parseDate(value, format).add(1, 'months');

      return date >= getNow();
    },
  }],

  [ValidatorName.MAX_DATE, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_DATE }) => message,

    isValid: (value, maxDate, format) => {
      const date = parseDate(value, format);
      return date.isValid() && date.isSameOrBefore(maxDate);
    },
  }],

  [ValidatorName.ZIP_CODE, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_ZIP_CODE }) => message,
    isValid: value => isString(value) && !!value.match(ZIP_CODE),
  }],

  [ValidatorName.MAX_LENGTH, {
    errorMessage: ({ message = ERROR_TYPE.INVALID_MAX_LENGTH }) => message,
    isValid: (value, maxLength) => isString(value) && value.length <= maxLength,
  }],
]);
