import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

import styles from './Errors.module.scss';

const Error = ({ error }) => (
  <div className={styles.errorMessage}>
    <FontAwesomeIcon className={styles.errorMessage__icon} icon={faExclamationCircle} />

    <span className={styles.errorMessage__text}>&nbsp;{error}</span>
  </div>
);

Error.propTypes = {
  error: PropTypes.string.isRequired,
};

export { Error };
