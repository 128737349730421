export const smoothstep = (min, max, value) => Math.max(0, Math.min(1, ( value - min ) / ( max - min )));
export const mix = (value1, value2, percent) => value1 * ( 1 - percent ) + value2 * percent;
export const randomInt = (min, max) => Math.floor(Math.random() * ( max - min + 1 )) + min;

export const clamp = (value, min = 0, max = 1) => Math.max(min, Math.min(value, max));
export const map = (value, min, max) => clamp(( value - min ) / ( max - min ), min, max);
export const random = (min, max) => Math.random() * ( max - min ) + min;
export const lerp = (v0, v1, t) => v0 + t * ( v1 - v0 );
export const percentage = (value, total) => total && (100 * value) / total;

export function* counterGenerator () {
  let counter = 0;

  while (true) {
    yield counter;
    counter++;
  }
}
