import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/pro-light-svg-icons';

import Button from './Button';

import {
  disabledState,
  loadingState,
  saveButton,
  faIcon,
} from './SaveButton.module.scss';

const SaveButton = ({
  children, icon,
  isEnabled, isLoading,
  onClick, className, ...props
}) => {
  const mounted = useRef(false);
  const [loading, setLoading] = useState(isLoading);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => void setLoading(isLoading), [isLoading]);

  const setLoadingState = isLoading =>
    mounted.current && setLoading(isLoading);

  const onButtonClick = () => {
    const promise = isEnabled
      ? Promise.resolve(onClick())
      : Promise.reject('SaveButton is disabled.');

    promise.finally(() => setLoadingState(false));
    setLoadingState(true);
  };

  const buttonIcon = loading
    ? <FontAwesomeIcon icon={faSpinner} className={faIcon} spin />
    : icon ? <FontAwesomeIcon icon={faCheck} className={faIcon} /> : null;

  return (
    <Button
      {...props}
      disabled={!isEnabled}
      onClick={onButtonClick}
      className={classNames(saveButton, className, {
        [disabledState]: !isEnabled,
        [loadingState]: loading,
      })}
    >
      {children}
      {buttonIcon}
    </Button>
  );
};

SaveButton.propTypes = {
  ...omit(Button.propTypes, ['secondary']),
  children: PropTypes.node.isRequired,

  isLoading: PropTypes.bool,
  isEnabled: PropTypes.bool,
  icon: PropTypes.bool,
};

SaveButton.defaultProps = {
  ...omit(Button.defaultProps, ['secondary']),
  isLoading: false,
  isEnabled: true,
  icon: true,
};

export default SaveButton;
