import moment from 'moment';

export const getAmPm = hour =>
  hour >= 12 ? 'pm' : 'am';

export const getNow = () => moment();

export const convert24Hour = hour => {
  const h = hour > 12 ? hour - 12 : hour;
  return h === '00' ? 12 : h;
};

export const parseDateTime = (value, format = null) =>
  value && moment(value, format);

export const parseDate = (value, format = 'YYYY-MM-DD') =>
  parseDateTime(value, format);

export const isToday = datetime => datetime.isSame(getNow(), 'day');
export const isTomorrow = datetime => datetime.isSame(getNow().add(1, 'days'), 'day');

export const getFormattedDateByDataRange = (startDateTime, endDateTime) => {
  const today = new Date();
  const tomorrow = new Date(today);
  const startTime = moment(startDateTime);
  const endTime = moment(endDateTime);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const isToday = startTime.isSame(today, 'day');
  let deliveryDate = startTime.format('dddd MMMM D,');
  const isTomorrow = startTime.isSame(tomorrow, 'day');

  if (isToday || isTomorrow) {
    const date = deliveryDate.slice(deliveryDate.indexOf(' ') + 1);
    deliveryDate = isToday ? `Today ${date}` : `Tomorrow ${date}`;
  }

  return `${deliveryDate} ${startTime.format('ha')} - ${endTime.format('ha')}`;
};
