import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Error } from './Error';

import { errorsBlock } from './Errors.module.scss';

const Errors = ({ errors, className }) => (
  !!errors && (
    <div className={classNames(errorsBlock, className)}>
      {errors.map((error, index) => (
        <Error key={`${index}_${error}`} error={error} />
      ))}
    </div>
  )
);

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string
};

export default Errors;
