import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { counterGenerator } from '../../utils/data/Number';

import styles from './TextInput.module.scss';

const idCounter = counterGenerator();

const TextInput = React.forwardRef(({
  labelClassName,
  unpackEvent,
  className,
  onChange,
  children,
  label,
  readOnly,
  ...props
}, ref) => {
  const [id, setId] = useState(null);
  const readOnlyValue = readOnly ? 'readonly' : '';

  useEffect(() => void
    setId(`TextInput-${idCounter.next().value}`)
  , []);

  return (
    <div className={styles.textInputWrapper}>
      <input
        onChange={event => onChange(unpackEvent ? event.target.value : event)}
        className={classNames(styles.textInput, className)}
        {...props}
        readOnly={readOnlyValue}
        ref={ref}
        id={id}
      />

      <label
        htmlFor={id}
        className={classNames(styles.inputLabel, labelClassName)}
      >
        {label}
      </label>

      {children}
    </div>
  );
});

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,

  unpackEvent: PropTypes.bool,
  className: PropTypes.string,

  children: PropTypes.node,
  onChange: PropTypes.func,

  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
};

TextInput.defaultProps = {
  labelClassName: '',
  unpackEvent: true,
  placeholder: ' ',
  value: undefined,

  onChange: noop,
  className: '',
  type: 'text',
  label: '',
};

export default TextInput;
