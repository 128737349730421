import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { chain, isEmpty, isEqual, noop, omit } from 'lodash';

import { FormContext } from '../forms';
import { Errors } from '../errors';
import MaskedInput from './MaskedInput';

import { counterGenerator } from '../../utils/data/Number';
import Validator, { ValidatorName } from '../../utils/Validator';

import styles from './ValidationInput.module.scss';

const idCounter = counterGenerator();

export default class ValidationInput extends Component {
  static contextType = FormContext;

  static propTypes = {
    ...MaskedInput.propTypes,

    validators: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              params: PropTypes.arrayOf(PropTypes.any),
              message: PropTypes.string,
            }),
          ],
        ),
      ),
    ),

    chainErrors: PropTypes.bool,
    isRequired: PropTypes.bool,
    requiredValidatorOptions: PropTypes.object,

    errors: PropTypes.array,
    onBlur: PropTypes.func,
    inputId: PropTypes.string,
    onKeyUp: PropTypes.func,
  };

  static defaultProps = {
    ...MaskedInput.defaultProps,

    chainErrors: true,
    isRequired: false,
    requiredValidatorOptions: {},
    showRequiredError: true,
    validators: [],
    inputId: null,

    onSubmit: noop,
    onBlur: noop,
    onKeyUp: noop,
    errors: [],
  };

  constructor (props) {
    super(props);

    this.id = props.inputId || `ValidationInput-${idCounter.next().value}`;
    this.onBlurEvent = this.onBlur.bind(this);

    this.state = {
      value: props.value || '',
      errors: props.errors,
      showErrors: false,
      changed: false,
    };
  }

  get validators () {
    const { validators, isRequired, requiredValidatorOptions } = this.props;

    return isRequired
      ? [[ValidatorName.REQUIRED, requiredValidatorOptions], ...validators]
      : validators;
  }

  get hasExternalErrors () {
    const { getErrors } = this.context;

    return !isEmpty(getErrors(this));
  }

  get hasErrors () {
    const { errors } = this.state;
    return !isEmpty(errors);
  }

  get errors () {
    const { errors } = this.state;
    return errors;
  }

  componentDidMount () {
    const { registerControl } = this.context;
    registerControl(this);
  }

  componentDidUpdate (prevProps) {
    const { value, errors } = this.props;
    const { value: currentValue } = this.state;

    if (prevProps.value !== value && currentValue !== value) {
      this.onChange(value);
    }

    if (!isEqual(errors, prevProps.errors)) {
      this.setState({ errors: errors });
    }
  }

  componentWillUnmount () {
    const { unregisterControl } = this.context;
    unregisterControl(this);
  }

  onChange (newValue) {
    const { onChange } = this.props;

    this.setState({ value: newValue, changed: true }, () => {
      const { onErrors } = this.context;
      const errors = this.validate();

      this.showErrors(false);
      onErrors(this, errors);

      return onChange(newValue, errors);
    });
  }

  onBlur () {
    const { onBlur } = this.props;
    const { value, changed } = this.state;

    if (typeof (value ?? '') === 'string') {
      this.showErrors(changed);
    }

    onBlur();
  }

  showErrors (showErrors) {
    const { showErrors: showContextErrors } = this.context;
    showContextErrors(this, showErrors);
    this.setState({ showErrors });
  }

  validate () {
    const { chainErrors } = this.props;

    const errors =
      chain(this.validators)
        .map(this.validateField.bind(this))
        .filter()
        .slice(0, chainErrors ? 1 : errors.length)
        .value();

    this.setState({ errors });
    return errors;
  }

  validateField (field) {
    const { value } = this.state;
    const [name, options = {}] = field;

    const params = options.params ?? [];
    const validator = Validator.get(name);

    return !validator.isValid(value, ...params) &&
      validator.errorMessage(options);
  }

  getKey () {
    const { htmlId } = this.props;
    return htmlId || this.id;
  }

  isValid = () => isEmpty(this.errors);

  render () {
    const { submit, hideInputErrors } = this.context;
    const { value, showErrors, errors } = this.state;

    const { onSubmit, inputMask, className, onKeyUp, ...props } = this.props;
    const componentProps = ['chainErrors', 'isRequired', 'validators', 'inputId', 'showRequiredError', 'requiredValidatorOptions'];

    const onKeyUpEvent = event => {
      onKeyUp(event);

      return event.key === 'Enter' &&
        (submit() || onSubmit(event.currentTarget.value));
    };

    const inputClassName = classNames(
      className,
      {
        [styles.hasError]: showErrors && (!isEmpty(errors) || this.hasExternalErrors),
      },
    );

    return (
      <div className={styles.inputBlock}>
        {showErrors && !hideInputErrors && <Errors errors={errors} />}

        <MaskedInput
          value={value}
          onChange={this.onChange.bind(this)}
          {...omit(props, componentProps)}
          className={inputClassName}
          onBlur={this.onBlurEvent}
          onKeyUp={onKeyUpEvent}
          inputMask={inputMask}
        />
      </div>
    );
  }
}
