import { CardType } from './CardNumber';

export const ZIP_CODE  = /^\d{5}$/;

export const CVV_CODE_DEFAULT          = /^\d{3}$/;
export const CVV_CODE_AMERICAN_EXPRESS = /^\d{4}$/;

export const US_PHONE_DIGITS = /^[0-9]{11}$/;
export const US_PHONE_REGEXP = /^1\s((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
export const US_CURRENCY     = /^\$?-?([1-9]{1}[0-9]{0,2}(,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^-?\$?([1-9]{1}\d{0,2}(,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/;

export const EMAIL = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

export const CARD_NUMBER_REGEXP_DEFAULT = /(\d{4})-(\d{4})-(\d{4})-(\d{4})$/;
export const CARD_NUMBER_REGEXP_AMERICAN_EXPRESS = /(\d{3})-(\d{6})-(\d{5})$/;

export const cvvCodeRegexObject = {
  [CardType.americanExpress]: CVV_CODE_AMERICAN_EXPRESS,
  [CardType.default]: CVV_CODE_DEFAULT,
};

export const getCvvCodeRegex = (currentCardType) => {
  return cvvCodeRegexObject[currentCardType] || cvvCodeRegexObject[CardType.default];
};

export const cardNumbersRegexObject = {
  [CardType.americanExpress]: CARD_NUMBER_REGEXP_AMERICAN_EXPRESS,
  [CardType.default]: CARD_NUMBER_REGEXP_DEFAULT,
};

export const getCardNumberRegexpByType = (currentCardType) => {
  return cardNumbersRegexObject[currentCardType] || cardNumbersRegexObject[CardType.default];
};
